<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <validation-observer ref="modalObserver" v-slot="{ handleSubmit }">
        <v-form
          class="newClass"
          @submit.prevent="
            handleSubmit(
              dataReceived.title === 'Add New Gift'
                ? addNewGift
                : updateExistingGift
            )
          "
          ref="modalForm"
        >
          <v-card-title class="card-title-dialog">
            <span class="text-h6">{{ dataReceived.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Gift Name"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Gift Name"
                      v-model="giftName"
                      class="form-control"
                      placeholder="Gift Name"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-gift"
                      :disabled="loading"
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider name="Active Event" mode="lazy">
                    <v-checkbox
                      label="Active Gift"
                      hide-details
                      class="mt-n2"
                      v-model="isActive"
                    ></v-checkbox>
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="text-center">
                  <span>Gift Image</span>
                  <div class="profile-pic-gift ma-auto">
                    <img :src="preview" id="gift-photo" />

                    <input
                      type="file"
                      id="file"
                      class="fileInput"
                      @change="previewImage($event)"
                      accept="image/*"
                    />

                    <label for="file" id="uploadBtn">Choose Photo</label>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="$emit('disableDialog', false), resetForm()"
            >
              Close
            </v-btn>
            <v-btn
              v-if="dataReceived.title == 'Add New Gift'"
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Add
            </v-btn>
            <v-btn
              v-else
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import GiftService from "../../services/GiftService";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import giftImagePreview from "@/assets/images/GiftImage.jpg";
import { checkFormatType } from "../../utils/helpers";
export default {
  watch: {
    dataReceived: {
      handler: function () {
        if (this.dataReceived.title === "Update Gift") {
          this.id = this.dataReceived.giftId;
          this.giftName = this.dataReceived.giftName;
          this.virtualFileName = this.dataReceived.giftVirtualFileName;
          this.origionalFileName = this.dataReceived.fileName;
          this.isActive = this.dataReceived.isActive;
          this.preview = this.dataReceived.s3FileUrl;
          this.giftImageData = this.dataReceived.s3FileUrl;
        }
      },
      deep: true,
    },
  },
  mounted() {},
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dataReceived: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  data: () => ({
    id: 0,
    giftName: "",
    virtualFileName: "",
    origionalFileName: "",
    isActive: true,
    actionBy: 1,
    giftImageData: "",
    loading: false,
    preview: giftImagePreview,
  }),

  methods: {
    async addNewGift() {
      try {
        this.loading = true;
        const response = await GiftService.addGift({
          id: 0,

          giftName: this.giftName,
          virtualFileName: "",
          origionalFileName: this.origionalFileName,
          isActive: this.isActive,
          actionBy: 1,
          giftImageData: this.giftImageData,
        });

        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Gift is added Successfully",
              type: "success",
            },
            { root: true }
          );
          this.resetForm();
          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }

        this.$emit("reloadGift");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async updateExistingGift() {
      try {
        this.loading = true;
        const response = await GiftService.updateGift({
          id: this.id,

          giftName: this.giftName,
          virtualFileName: !this.giftImageData.includes("http")
            ? ""
            : this.virtualFileName,
          origionalFileName: this.origionalFileName,
          isActive: this.isActive,
          actionBy: 1,
          giftImageData: this.giftImageData.includes("http")
            ? ""
            : this.giftImageData,
        });
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Gift is updated Successfully",
              type: "success",
            },
            { root: true }
          );
          this.resetForm();
          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.$emit("reloadGift");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    previewImage(event) {
      var input = event.target;
      let isOk = checkFormatType(
        input.files[0].type,
        input.files[0].size,
        "image"
      );

      if (input.files && isOk === "ok") {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
          this.giftImageData = e.target.result.split("base64,")[1];
        };
        reader.readAsDataURL(input.files[0]);

        // this.giftImageData = reader.result.split("base64,")[1];
        this.origionalFileName = input.files[0].name;
      }
    },

    resetForm() {
      this.$refs.modalObserver.reset();
      // this.$refs.modalForm.reset();
      this.id = 0;
      this.giftName = "";
      this.virtualFileName = "";
      this.origionalFileName = "";
      this.isActive = true;
      this.actionBy = 1;
      this.giftImageData = "";
      this.loading = false;
      this.image = "";
      this.preview = giftImagePreview;
    },
  },
};
</script>
