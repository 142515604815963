<template>
  <master-layout>
    <div class="mb-3 text-right">
      <v-btn
        class="ml-3 add-btn"
        dark
        @click="addOrUpdateGift('add')"
        :disabled="!accessEnableAdd"
      >
        <v-icon>mdi-plus</v-icon> Add Gift
      </v-btn>
    </div>
    <v-card>
      <v-row class="ma-0">
        <v-col cols="12" sm="8">
          <v-card-title class="pa-0">
            Gift List
            <!-- <v-spacer></v-spacer> -->
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            max-width="400px"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        item-key="id"
        :headers="registerHeader"
        :items="registrationList"
        class="elevation-1 mytable"
        :loading="loading"
        must-sort
        :search="search"
      >
        <template v-slot:[`header.name`]="{ header }">
          {{ header.text.toUpperCase() }}
        </template>

        <template v-slot:[`item.phoneNumber`]="{ item }">
          {{
            item.phoneNumber
              .replace(/\D+/g, "")
              .replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "$1-$2-$3")
          }}
        </template>
        <template v-slot:[`item.s3FileUrl`]="{ item }">
          <img :src="item.s3FileUrl" style="width: 100px" :alt="item" />
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon style="color: #f0ce6a" v-if="item.isActive"
            >mdi-check-bold</v-icon
          >
          <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                text
                :disabled="!accessEnableUpdate"
                v-bind="attrs"
                v-on="on"
                @click="addOrUpdateGift('update', item)"
              >
                <v-icon color="primary">mdi-pencil</v-icon></v-btn
              >
            </template>
            Edit Gift
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <!-- <progress-bar :show="loading" /> -->
    <!-- <v-simple-table fixed-header height="600px" class="d-none d-sm-block">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">Image</th>
            <th class="text-center">Gift Name</th>

            <th class="text-center">Created On</th>
            <th class="text-center">Active</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr align="center" v-for="item in giftDetail" :key="item.id">
            <td>
              <div class="event-img-box img-fit-box">
                <img
                  src="../assets/GiftImage.jpg"
                  style="width: 100px"
                  alt=""
                />
              </div>
            </td>
            <td>{{ item.giftName }}</td>

            <td>{{ item.addedOn }}</td>
            <td>
              <v-icon style="color: #f0ce6a" v-if="item.isActive"
                >mdi-check-bold</v-icon
              >
              <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
            </td>
            <td>
              <v-btn
                text
                class="edit-pancil"
                @click="addOrUpdateGift('update', item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
    <!-- mobile view -->
    <!-- <v-card
      class="d-sm-none mobile_card mb-3"
      elevation="1"
      outlined
      v-for="item in giftDetail"
      :key="item.id"
    >
      <div class="d-flex align-center card-head">
        <div>
          <strong>{{ item.giftName }}</strong>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="pr-0 m-edit"
          @click="addOrUpdateGift('update', item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <div class="d-flex pt-3 crad-body-content">
        <div class="d-flex">
          <ul class="list">
            <li class="list-address-info">
              <div class="event-img-box img-fit-box mr-3 mr-md-0">
                <img
                  src="../assets/GiftImage.jpg"
                  style="width: 100px"
                  alt=""
                />
              </div>
              <span>{{ item.s3FileUrl }}</span>
            </li>
          </ul>
          <ul class="list">
            <li class="list-call-info"></li>
            <li>
              <label>Created On</label>
              <span>{{ item.addedOn }}</span>
            </li>
           
            <li class="list-active-info">
              <label>Active</label>
              <span>
                <v-icon style="color: #f0ce6a" v-if="item.isActive"
                  >mdi-check-bold</v-icon
                >
                <v-icon style="color: #f0ce6a" v-else>mdi-close-thick</v-icon>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </v-card> -->

    <GiftModal
      :dialog="dialog"
      :dataReceived="updateGiftValue"
      @reloadGift="getGiftList()"
      @disableDialog="dialog = $event"
    />
  </master-layout>
</template>
<script>
import GiftService from "../services/GiftService.js";
// import Modals from "../components/GiftModals.vue";
import GiftModal from "../components/Modals/GiftModal.vue";
import { actionEnable } from "../utils/helpers";
export default {
  components: {
    // Modals,
    GiftModal,
  },
  name: "Gifts",
  data: () => ({
    name: "",
    website: "",
    address: "",
    phone: "",
    latitude: "",
    longitude: "",
    giftDetail: [],
    dialog: false,
    updateGiftValue: {},
    loading: "",
    registerHeader: [
      { text: "Image", value: "s3FileUrl", width: 150, sortable: false },
      { text: "Gift Name", value: "giftName", width: 150 },
      { text: "Created On", value: "addedOn", width: 100 },
      { text: "Active", value: "isActive", width: 100, sortable: false },
      { text: "Actions", value: "action", width: 100, sortable: false },
    ],
    registrationList: [],
    search: "",
  }),
  async mounted() {
    this.getGiftList();
  },
  computed: {
    accessEnableAdd() {
      return actionEnable("Gift", "Add", 1);
    },
    accessEnableUpdate() {
      return actionEnable("Gift", "Update", 1);
    },
  },
  methods: {
    async getGiftList() {
      try {
        this.loading = true;
        const response = await GiftService.giftList();
        this.registrationList = response.data.data;
        this.giftDetail = response.data.data;
        if (response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    async getGiftDetails() {
      try {
        const { data } = await GiftService.getGiftDetail({});
        this.giftDetail = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    addOrUpdateGift(value, item) {
      if (value == "add") {
        this.dialog = true;
        this.updateGiftValue = { title: "Add New Gift" };
      } else {
        this.dialog = true;
        this.updateGiftValue = { ...item, title: "Update Gift" };
      }
    },
  },
};
</script>
